import {customElement, property} from 'lit/decorators.js';
import {SbkButton} from "@/components/button/button";
// eslint-disable-next-line
declare let _paq: any

@customElement('sbk-oip-login-button')
export class OipLoginButton extends SbkButton {

    @property({type: Boolean, attribute: 'is-oip-lite'})
    isOipLite = false;

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.trackOip)
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('click', this.trackOip)
    }

    trackOip() {
        if (this.isOipLite) {
            _paq.push(['trackEvent', 'OIP', 'Aufruf OIP Lite']);
        } else {
            _paq.push(['trackEvent', 'OIP', 'Aufruf OIP']);
        }
    }
}
